<template>
    <SimpleTable formclass="pages/Residents/form" endpoint="api/v2/resident" :aditionalColumns="headers" :hideNew="!isAdmin" :hideDelete="!isAdmin">
        <template v-slot="data">
            <h1>Moradores</h1>
            <v-divider class="mb-4" />

            <v-layout wrap class="my-12">
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ total(data.items) }}
                            </div>
                            Cadastrados
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ active(data.items) }}
                            </div>
                            Ativos
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ pending(data.items) }}
                            </div>
                            Pendentes
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
import { mapGetters } from "vuex";

export default {
    components: { SimpleTable },

    mounted() {
        this.isAdmin = this.verifyRole("ROLE_ADMIN");
    },

    computed: {
        ...mapGetters(["verifyRole"]),
    },

    data() {
        return {
            isAdmin: false,
            headers: [
                { text: "Status", value: "status", align: "right", sortable: true },
                { text: "Apartamento", value: "apartment", align: "right", sortable: true },
                { text: "Bloco", value: "block", align: "right", sortable: true },
                { text: "Condomínio", value: "condominium.name", align: "right", sortable: true },
                { text: "Email", value: "user.email", align: "right", sortable: true },
                { text: "Usuário", value: "user.name", align: "right", sortable: true },
            ],
        };
    },

    methods: {
        total(items) {
            return Number.parseFloat(items.length).toFixed(0);
        },
        active(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.status == "OK" ? sum + 1 : sum), 0)).toFixed(0);
        },
        pending(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.status == "PENDING" ? sum + 1 : sum), 0)).toFixed(0);
        },
    },
};
</script>